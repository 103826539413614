.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr; /* 4 columns */
  padding: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  font-size: 20px;
  text-align: center;
}

.grid-item canvas {
  max-width: 100%;
}

/* 트리 노드 스타일링 */
.tree-node {
  margin-left: 20px;
  cursor: pointer;
}

/* 자식 노드 스타일링 */
.tree-children {
  margin-left: 20px;
  border-left: 1px solid #ddd;
  padding-left: 10px;
}

/* Bold for node titles */
.bold-text {
  font-weight: bold;
  margin-right: 10px; /* 속성 정보와 간격 추가 */
}

/* Light for attributes */
.light-text {
  font-weight: normal;
}

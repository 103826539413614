::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #9c9c9c; 
    border-radius: 100px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #7a7a7a;
  }
  
  .MuiDataGrid-root {
    font-family: 'Noto Sans KR', sans-serif !important;
  }